import {useEffect} from 'react';
import { useFormik } from "formik";
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Yup from "yup";
import { requestClienteByDoc } from '../../actions/cliente';
import { changePasswordEmail } from '../../actions/auth';
import { useHistory } from "react-router-dom";
import rutas from '../../routes/constants/routes';
import TokenService from '../../services/token';

const ChangePassword = (props) => {

    let { nroDoc } = useParams();
    let { email } = useParams();    

    const captchaTokenLS = localStorage.getItem('_grecaptcha');

    const history = useHistory();

    useEffect(() => {
        if(props.successPasswordEmail){
            history.push(rutas.LOGIN);
            window.location.reload();
        }
    }, [props])

    
    const passClarification = {
        fontSize: 15,
        fontWeight: 400,
        letterSpacing: -0.3,
        color:'#505050',
        paddingTop:10,
    }
 
    const formik = useFormik({
        initialValues: {
            identification: nroDoc,
            email: email,
            passwordCopy: "",
            newPassword: "",
            password: "",
            captchaToken: captchaTokenLS,
            ChangePassword: true

        },
        validationSchema: Yup.object({
            passwordCopy: Yup.string().required("Ingrese una contraseña")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!.@#\$%\^&\*])(?=.{8,})/,
                "Debe tener: 1 mayúscula, 1 número, 1 caracter especial y una longitud minima de 8"
            ),
            newPassword: Yup.string().required("Ingrese nuevamente la contraseña")
            .test('passwords-match', 'Las contraseñas deben coincidir', function(value){
                return this.parent.passwordCopy === value
            }),
            password: Yup.string()
        }),
        onSubmit: (data) => {
            props.changePasswordEmail(data);
        }
    })

    useEffect(() => {
        if (nroDoc !== undefined) {
            props.requestClienteByDoc(nroDoc);
        }
    }, []);

    return (
        <>
        <div>
            <div className="d-grid gap-2 col-12 col-md-5 mx-auto" style={{marginTop:"2.25rem", marginBottom:"2.25rem"}}>
                <div className="container" style={{margin: "20px 0px"}}>
                        <div className="d-flex flex-row" style={{backgroundColor: "#e2e2e2", padding: "15px", borderRadius: "10px"}}>
                            <div className="float-start" style={{fontWeight:"bold", marginRight:"1.25rem"}}>
                                <h4>N° Cliente</h4>
                                <h4>CNPJ</h4>
                                <h4>Razão Social</h4>
                            </div>
                            <div className="float-end" style={{color:"gray", fontWeight:200}}>
                                <h4>{props.cliente?.id}</h4>
                                <h4>{nroDoc}</h4>
                                <h4>{props.cliente?.razonSocial}</h4>
                            </div>
                        </div>
                </div>
                <form className="container" onSubmit={formik.handleSubmit}>
                {TokenService.hasSession()
                    && 
                        (<div className="mb-3">
                        <label for="password" className="form-label" style={{color:"gray",fontWeight:600,fontSize:"0.938rem"}}>Senha atual:</label>
                        <input  onChange={formik.handleChange} error={formik.errors.password} type="password" className="form-control" id="password" name='password' style={{borderColor:"black",padding:"0.625rem"}}></input>
                        {formik.touched.password && formik.errors.password ? (
                        <div className='text-danger'>{formik.errors.password}</div>
                        ): null}
                        </div>
                        )
                }
                        
                        <div className="mb-3">
                            <label for="passwordCopy" className="form-label" style={{color:"gray",fontWeight:500,fontSize:"1.15rem"}}>Nova senha:</label>
                            <input  onChange={formik.handleChange} error={formik.errors.passwordCopy} type="password" className="form-control" id="passwordCopy" name='passwordCopy' style={{borderColor:"black",padding:"0.625rem"}}></input>
                            {formik.touched.passwordCopy && formik.errors.passwordCopy ? (
                            <div className='text-danger'>{formik.errors.passwordCopy}</div>
                            ): null}
                            <p style={passClarification}>&#40;Deve possuir 1 maiúscula, 1 número, 1 carácter especial.&#41;</p>
                        </div>
                        <div className="mb-3">
                            <label for="newPassword" className="form-label" style={{color:"gray",fontWeight:500,fontSize:"1.15rem"}}>Repetir senha:</label>
                            <input  onChange={formik.handleChange} error={formik.errors.newPassword} type="password" className="form-control" id="newPassword" name='newPassword' style={{borderColor:"black",padding:"0.625rem"}}></input>
                            {formik.touched.newPassword && formik.errors.newPassword ? (
                            <div className='text-danger'>{formik.errors.newPassword}</div>
                            ): null}
                        </div>
                        <div className="d-grid gap-2 col-12 col-md-6 mx-auto">
                            <button disabled={props.error} className="btn btn-danger" style={{backgroundColor: "#E3000B", padding: "0.625rem", marginTop:"1.25rem"}} type="submit">Confirmar</button>
                        </div>
                        {
                            props.error &&
                            <div class="alert alert-danger" style={{ maxWidth: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }} role="alert">
                                { props.error.response.data.message }
                            </div>
                        }
                </form>
            </div>
        </div>
    </>
    )
}

export default connect(
    state => ({
        loading: state.auth.loading,
        successPasswordEmail: state.auth.successPasswordEmail,
        cliente: state.clientes.cliente,
        error: state.auth.error
    }),
    dispatch => ({
        requestClienteByDoc: (nroDoc) => dispatch(requestClienteByDoc(nroDoc)),
        changePasswordEmail: (data) => dispatch(changePasswordEmail(data))
    })
)(ChangePassword);
